<template>
    <div class="chat-container" @wheel.stop>
        <div class="chat-header">
            <h2>天清数安助手</h2>
            <div class="header-controls">
                <!-- <button class="control-btn clear" @click="clearHistory" title="清除历史记录">
                    <span class="clear-icon">🗑️</span>
                </button> -->
                <button class="control-btn minimize" @click="minimize">－</button>
                <button class="control-btn close" @click="close">×</button>
            </div>
        </div>

        <div class="chat-messages" ref="chatMessages" @wheel="handleScroll" @touchmove="handleTouchMove">
            <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.isUser ? 'user-message' : 'bot-message']">
                <div class="message-bubble">
                    <div class="avatar" :style="getAvatarStyle(msg.isUser)"></div>
                    <div class="message-content" v-html="msg.isUser ? msg.content : marked(msg.content)"></div>
                </div>
                <div class="message-time">{{ msg.time }}</div>
            </div>
        </div>
        <div class="input-area">
            <div class="quick-replies">
                <div v-for="(category, idx) in questions" :key="idx" class="category-group">
                    <div class="question-category" v-if="category.category">
                        {{ category.category }}
                        <button v-if="category.items.length > category.showCount" class="expand-btn" @click="toggleExpand(idx)">
                            {{ isProductExpanded ? '收起' : '展开' }}
                        </button>
                    </div>
                    <div class="button-group">
                        <button v-for="(question, qIdx) in getVisibleItems(category)" :key="qIdx" @click="quickReply(question)">
                            {{ question }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="input-group">
                <input type="text" v-model="userInput" placeholder="请输入您的问题..." @keypress.enter="sendMessage" autocomplete="off">
                <button class="send-button" @click="sendMessage">发送</button>
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked'
import wxQRCode from '@/assets/images/wxchart2.png'

// 替换原来的 cookieUtils
const storageUtils = {
    setStorage(name, value) {
        try {
            localStorage.setItem(name, JSON.stringify(value))
            return true
        } catch (e) {
            console.error('Storage failed:', e)
            return false
        }
    },

    getStorage(name) {
        try {
            const value = localStorage.getItem(name)
            return value ? JSON.parse(value) : null
        } catch (e) {
            console.error('Get storage failed:', e)
            return null
        }
    },

    removeStorage(name) {
        try {
            localStorage.removeItem(name)
            return true
        } catch (e) {
            console.error('Remove storage failed:', e)
            return false
        }
    }
}

export default {
    name: 'KeFu',

    data() {
        return {
            wxQRCode,
            userInput: '',
            messages: [],
            isProductExpanded: false,
            questions: [
                {
                    category: "产品系列",
                    items: ["天清天澜智能溺水监测预警系统介绍", "天清天澜智能溺水监测预警系统功能", "金融行业大模型应用平台介绍", "金融行业大模型应用平台功能", "医疗大模型应用平台介绍",
                        "医疗大模型应用平台功能",
                        "智能地理绘图系统介绍", "智能地理绘图系统功能", "智能全周期数据资产安全管理平台介绍", "智能全周期数据资产安全管理平台功能", "数据分级分类系统介绍", "数据分级分类系统功能"],
                    showCount: 4,
                },
                {
                    category: "",
                    items: ["行业案例", '联系我们']
                }
            ],
            autoReplies: {
                /* 1 */
                "天清天澜智能溺水监测预警系统介绍": `## 天清天澜智能溺水监测预警系统
天澜智能溺水监测预警系统，运用先进的人工智能算法，可全天候全方位实时监控。能自动识别人员水中行为状态，快速捕捉异常行为，10s 内向救生员推送溺水位置，把握了黄金救援时间。该系统在各类专业泳馆采集超 5 万条监测数据，在复杂环境下识别准确率高达98%，适用于多种场景，推动水域安全管理。`,
                "天清天澜智能溺水监测预警系统功能": `## 天清天澜智能溺水监测预警系统
使用先进的人工智能技术识别游泳者异常行为，对潜在溺水情况进行预警。报警信息同时发送至救生员（智能手环）与管理员（终端设备），实现秒级响应。
`,
                /* 2 */
                "金融行业大模型应用平台介绍": `## 金融行业大模型应用平台 
天清数安自主研发的金融行业大模型工具天融智汇功能强大。它集成专业 AI 问答系统精准解疑，同时配备文档分析功能，能够提炼金融数据中的关键信息。还融入 AI 写作模块，生成金融报告等，提供智能 PPT 生成功能，从而提升金融从业者工作效率，助力汇报。
`,
                "金融行业大模型应用平台功能": `## 金融行业大模型应用平台
- 专业金融行业信息智能问答
- 各类金融报告文档总结分析
- 各类金融报告智能生成
- 金融汇报演示文稿自动生成`,
                /* 3 */
                "医疗大模型应用平台介绍": `## 医疗大模型应用平台
天清数安研发的针对医疗行业大模型运用先进的OCR技术和图像处理等技术，极大地优化了医药公司的数据收集流程，实现了从纸质文档到数字信息的无缝转换，显著减少了人工处理的时间和成本，有效提升了工作效率，真正做到了降本增效。`,
                "医疗大模型应用平台功能": `## 医疗大模型应用平台
- 各类医疗报告便捷上传
- 各类报告内容智能提取
- 患者信息资源画像自动生成
`,
                /* 4 */
                "智能地理绘图系统介绍": `## 智能地理绘图系统
"天绘--智能地理绘图系统"采用智能算法实现设计图一键导入、无序点集合分类排序、自动连线和界址线自动生成，将生成的地理信息数据以可视化的方式展示给用户，避免因传统的手工处理方式产生的耗时、耗力、易出错等问题，以灵活匹配度和行业适配性，自动化处理地理信息数据，为土地管理和规划领域处理地理信息数据提供技术支撑。  `,
                "智能地理绘图系统功能": `## 智能地理绘图系统
采用智能算法实现设计图一键导入、无序点集合分类排序、自动连线和界址线自动生成，将生成的地理信息数据以可视化的方式展示给用户。`,
                /* 5 */
                "智能全周期数据资产安全管理平台介绍": `## 智能全周期数据资产安全管理平台
智能全周期数据资产安全管理平台帮助用户构建安全、高效、智能的数据管理体系，有效整合数据资源，挖掘数据价值， 为企业决策提供科学支撑的同时保障数据资产安全，助力企业数智化转型，增强业务响应速度与市场竞争力，实现企业的可持续发展。 `,
                "智能全周期数据资产安全管理平台功能": `## 智能全周期数据资产安全管理平台
基础功能：元数据管理、数据标准管理、数据质量管理、数据清洗
核心功能：数据分级分类、数据脱敏、数据评估、数据交易 `,
                /* 6 */
                "数据分级分类系统介绍": `## 数据分级分类系统
"数据分级分类系统"通过AI算法自动识别系统的各类文件，根据相关行业标准，对数据进行分级分类，给出可视化的统计结果； 并对敏感数据进行预警和屏蔽处理，实现数据的有效提取生成分级分类结果报告。 从而提高数据安全性、简化合规性管理、改善数据质量，促进数据有效使用。 `,
                "数据分级分类系统功能": `## 数据分级分类系统
通过AI算法自动识别系统的各类文件，根据相关行业标准，对数据进行分级分类，给出可视化的统计结果； 并对敏感数据进行预警和屏蔽处理，实现数据的有效提取生成分级分类结果报告。`,
                "行业案例": `## 行业案例咨询

<p style="text-indent: 2em;">我们有不同行业的成功合作案例。请问您是属于什么行业呢？可以留下您的联系方式，我们将通过微信为您发送相关案例。</p>`,
                "联系我们": `## 联系我们

尊敬的客户，感谢您对天清数安的关注！

### 
您可以随时拨打我们的客服热线：022-27366066

### 
您也可以通过添加企业微信联系我们

<div style=" display: flex; align-items: center; justify-content: center; background: #f5f5f5; margin: .2rem 0; border-radius: .08rem;">
    <img src="${wxQRCode}" style="width:3.5rem; height: auto;">
</div>

我们的在线客服工作时间为工作日9:00至18:00，期待与您的沟通，为您提供卓越的服务体验。 `
            }
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight
        })
        // console.log('wxQRCode path:', this.wxQRCode)
        // 从 localStorage 读取历史记录
        const savedMessages = storageUtils.getStorage('chatHistory')
        if (savedMessages && savedMessages.length) {
            this.messages = savedMessages
            this.scrollToBottom()
        } else {
            this.initChat()
        }
        marked.setOptions({ breaks: true })

        // 添加滚动事件处理
        const chatMessages = this.$refs.chatMessages
        chatMessages.addEventListener('wheel', this.handleScroll, { passive: false })
    },

    beforeDestroy() {
        // 组件销毁时移除事件监听
        const chatMessages = this.$refs.chatMessages
        chatMessages.removeEventListener('wheel', this.handleScroll)
    },

    methods: {
        marked(content) {
            return marked(content)
        },
        // 2. 产品咨询
        // - 产品价格咨询
        initChat() {
            const welcomeMessage = `
<p style="text-indent: 2em;">天清数安（天津）科技有限公司成立于2023 年，聚焦人工智能、数据管理和数据安全等前沿领域，致力于为各行业提供"智能+安全"的数据服务。 </p>
<p style="text-indent: 2em;">天清数安依托顶尖技术团队和丰富的业务场景，自主研发的数字化产品包括AI 智能软件、垂直领域大模型以及全周期数据资产智能管理平台等，能够全面提升企业的数字化水平，优化生成和工作流程，显著增强业务的敏捷性和透明度，并有效整合数据资源，挖掘数据价值，保护数据安全，助力企业实现高效运营、精准决策与可持续发展。</p> 


> 您好， 请问有什么可以帮您？`

            this.addMessage(welcomeMessage, false)
        },

        scrollToBottom() {
            setTimeout(() => {
                if (this.$refs.chatMessages) {
                    this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight
                }
            }, 100)
        },

        addMessage(content, isUser) {
            // 限制保存最近的50条消息（可以存储更多了）
            if (this.messages.length >= 50) {
                this.messages.shift()
            }

            this.messages.push({
                content,
                isUser,
                time: new Date().toLocaleTimeString('zh-CN', {
                    hour: '2-digit',
                    minute: '2-digit'
                })
            })

            // 使用 localStorage 存储
            storageUtils.setStorage('chatHistory', this.messages)

            this.scrollToBottom()
        },

        getAvatarStyle(isUser) {
            const userAvatar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0ZGRiIgZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgM2MxLjY2IDAgMyAxLjM0IDMgM3MtMS4zNCAzLTMgMy0zLTEuMzQtMy0zIDEuMzQtMyAzLTN6bTAgMTQuMmMtMi41IDAtNC43MS0xLjI4LTYtMy4yMi4wMy0xLjk5IDQtMy4wOCA2LTMuMDggMS45OSAwIDUuOTcgMS4wOSA2IDMuMDgtMS4yOSAxLjk0LTMuNSAzLjIyLTYgMy4yMnoiLz48L3N2Zz4="
            const botAvatar = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzE5NzZEMiIgZD0iTTEyIDJDNi40OCAyIDIgNi40OCAyIDEyczQuNDggMTAgMTAgMTAgMTAtNC40OCAxMC0xMFMxNy41MiAyIDEyIDJ6bTAgMThjLTQuNDEgMC04LTMuNTktOC04czMuNTktOCA4LTggOCAzLjU5IDggOC0zLjU5IDgtOCA4em0wLTEyLjVjLTIuNDkgMC00LjUgMi4wMS00LjUgNC41czIuMDEgNC41IDQuNSA0LjUgNC41LTIuMDEgNC41LTQuNS0yLjAxLTQuNS00LjUtNC41em0wIDUuNWMtLjU1IDAtMS0uNDUtMS0xczAtLjQ1IDEtLjQgMS40NSAxIDEgMS0uNDUgMS0xIDF6Ii8+PC9zdmc+"

            return {
                backgroundImage: `url('${isUser ? userAvatar : botAvatar}')`
            }
        },

        quickReply(question) {
            this.userInput = question
            this.sendMessage()

        },

        sendMessage() {
            const message = this.userInput.trim()
            if (message) {
                this.addMessage(message, true)
                this.userInput = ''

                setTimeout(() => {
                    let response = this.autoReplies[message]

                    if (!response) {
                        for (let key in this.autoReplies) {
                            if (message.includes(key) && key !== 'default') {
                                response = this.autoReplies[key]
                                break
                            }
                        }
                        
                        if (!response) {
                            response = `抱歉，我暂时无法回答这个问题。您可以：

1. 尝试换个方式提问
2. 点击下方快捷按钮查看相关信息
3. 联系我们的客服人员获取帮助`
                        }
                    }

                    this.addMessage(response, false)
                }, 500)
            }
        },
        minimize() {
            this.$emit('close')
        },
        close() {
            storageUtils.setStorage('chatHistory', this.messages)
            this.$emit('close')
        },
        toggleExpand(idx) {
            this.isProductExpanded = !this.isProductExpanded
        },
        getVisibleItems(category) {
            if (!category.showCount || this.isProductExpanded) {
                return category.items;
            }
            return category.items.slice(0, category.showCount);
        },
        // 添加清除历史记录的方法
        clearHistory() {
            this.messages = []
            storageUtils.removeStorage('chatHistory')
            this.initChat()
        },
        handleScroll(e) {
            const el = this.$refs.chatMessages
            const scrollTop = el.scrollTop
            const scrollHeight = el.scrollHeight
            const height = el.clientHeight

            if (
                (scrollTop <= 0 && e.deltaY < 0) || 
                (scrollTop + height >= scrollHeight && e.deltaY > 0)
            ) {
                e.preventDefault()
            }
        },
        handleTouchMove(e) {
            const el = this.$refs.chatMessages
            const scrollTop = el.scrollTop
            const scrollHeight = el.scrollHeight
            const height = el.clientHeight

            if (
                (scrollTop <= 0) || 
                (scrollTop + height >= scrollHeight)
            ) {
                e.stopPropagation()
            }
        }
    }
}
</script>

<style scoped lang="scss">
/* 复制之前的所有 CSS 样式到这里 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.chat-container {
    position: fixed;
    right: 0.8rem;
    bottom: 0.5rem;
    width: 5rem;
    height: 90vh;
    background: white;
    border-radius: 0.12rem;
    box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    isolation: isolate;
    contain: content;
    z-index: 999;
}

.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.12rem 0.16rem;
    background: linear-gradient(90deg, #2196f3, #1976d2);
    color: white;
    border-radius: 0.12rem 0.12rem 0 0;
}

.chat-header h2 {
    font-size: 0.16rem;
    font-weight: 500;
}

.chat-messages {
    flex: 1;
    overflow-y: auto;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    padding: 0.15rem;
    background: #f8f9fa;
}

.message {
    margin-bottom: 0.16rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.message-bubble {
    display: flex;
    align-items: flex-start;
    gap: 0.08rem;
}

.user-message .message-bubble {
    flex-direction: row-reverse;
}

.avatar {
    width: 0.32rem;
    height: 0.32rem;
    border-radius: 50%;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    border: 0.02rem solid #fff;
    box-shadow: 0 0.02rem 0.04rem rgba(0, 0, 0, 0.1);
}

.message-content {
    padding: 0.1rem 0.14rem;
    border-radius: 0.12rem;
    font-size: 0.13rem;
    line-height: 1.5;
    box-shadow: 0 0.01rem 0.02rem rgba(0, 0, 0, 0.1);
}

.bot-message .message-content {
    background: white;
    border-bottom-left-radius: 0.04rem;
    max-width: 85%;
}

.user-message .message-content {
    background: #1976d2;
    color: white;
    border-bottom-right-radius: 0.04rem;
    max-width: 70%;
}

.message-time {
    font-size: 0.11rem;
    color: #999;
    margin: 0.02rem 0.4rem;
}

.user-message .message-time {
    text-align: right;
}

.input-area {
    padding: 0.12rem 0.16rem;
    background: white;
    border-top: 0.01rem solid #eee;
}

.input-group {
    display: flex;
    align-items: center;
    gap: 0.1rem;
    background: #f8f9fa;
    padding: 0.06rem;
    border-radius: 0.24rem;
    border: 0.01rem solid #e0e0e0;
}

.input-group:focus-within {
    border-color: #1976d2;
    box-shadow: 0 0 0 0.02rem rgba(25, 118, 210, 0.1);
}

.input-group input {
    flex: 1;
    padding: 0.08rem 0.16rem;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.14rem;
    color: #333;
}

.send-button {
    background: #1976d2;
    color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 0.08rem 0.2rem;
    font-size: 0.14rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.send-button:hover {
    background: #1565c0;
}

.send-button::after {
    content: "→";
    margin-left: 0.04rem;
}

.quick-replies {
    margin-bottom: 0.12rem;
}

.category-group {
    background: white;
    border-radius: 0.06rem;
    padding: 0.08rem;
    margin-bottom: 0.08rem;
    box-shadow: 0 0.01rem 0.03rem rgba(0, 0, 0, 0.05);
}

.question-category {
    font-size: 0.13rem;
    font-weight: 500;
    color: #1976d2;
    margin-bottom: 0.06rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expand-btn {
    padding: 0.02rem 0.08rem;
    font-size: 0.12rem;
    color: #1976d2;
    background: transparent;
    border: 0.01rem solid #1976d2;
    border-radius: 0.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.expand-btn:hover {
    background: #1976d2;
    color: white;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.06rem;
}

.button-group button {
    padding: 0.04rem 0.1rem;
    border: 0.01rem solid #e0e0e0;
    border-radius: 0.12rem;
    background: white;
    color: #666;
    font-size: 0.12rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-group button:hover {
    background: #1976d2;
    color: white;
    border-color: #1976d2;
}

.header-controls {
    display: flex;
    gap: 0.08rem;
}

.control-btn {
    width: 0.24rem;
    height: 0.24rem;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.16rem;
    color: #1976d2;
    background: white;
    transition: all 0.3s ease;
}

.control-btn:hover {
    opacity: 0.9;
    transform: scale(1.1);
}

.control-btn.close:hover {
    background: #ff4444;
    color: white;
}

.control-btn.clear {
    font-size: 0.14rem;

    .clear-icon {
        opacity: 0.8;
        transition: all 0.3s ease;
    }

    &:hover {
        .clear-icon {
            opacity: 1;
        }
    }
}

.chat-minimized {
    position: fixed;
    right: 0.8rem;
    bottom: 0.5rem;
    background: #1976d2;
    color: white;
    padding: 0.12rem 0.24rem;
    border-radius: 0.24rem;
    cursor: pointer;
    box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.2);
    z-index: 999;
    transition: all 0.3s ease;
}

.chat-minimized:hover {
    background: #1565c0;
    transform: scale(1.05);
}

@media (max-width: 750px) {
    .chat-container {
        width: 100%;
        height: 100vh;
        margin: 0;
        border-radius: 0;
    }
}

/* 在现有样式的基础上添加表格样式 */
.message-content :deep(table) {
    width: 100%;
    border-collapse: collapse;
    margin: 0.12rem 0;
    background: #fff;
    border-radius: 0.08rem;
    overflow: hidden;
    box-shadow: 0 0.02rem 0.04rem rgba(0, 0, 0, 0.05);
}

.message-content :deep(th),
.message-content :deep(td) {
    padding: 0.08rem 0.12rem;
    text-align: left;
    border-bottom: 0.01rem solid #eee;
    font-size: 0.13rem;
}

.message-content :deep(th) {
    background: #f8f9fa;
    font-weight: 500;
    color: #1976d2;
    white-space: nowrap;
}

.message-content :deep(tr:last-child) td {
    border-bottom: none;
}

.message-content :deep(tr:hover) td {
    background: #f5f9ff;
}

.message-content :deep(td:nth-child(3)) {
    color: #f44336;
    font-weight: 500;
}
.message-content :deep(p) {
    margin: 0.12rem 0;
    line-height: 1.6;
    color: #333;
}
.message-content :deep(h2) {
    color: #1976d2;
    font-size: 0.18rem;
    margin: 0.16rem 0 0.12rem;
    padding-bottom: 0.1rem;
    border-bottom: 0.01rem solid #eee;
}

.message-content :deep(h3) {
    color: #333;
    font-size: 0.16rem;
    margin: 0.14rem 0 0.1rem;
    font-weight: 500;
}

.message-content :deep(ul),
.message-content :deep(ol) {
    padding-left: 0.2rem;
    margin: 0.1rem 0;
}

.message-content :deep(li) {
    margin: 0.06rem 0;
    line-height: 1.6;
    color: #444;
}

.message-content :deep(blockquote) {
    border-left: 0.04rem solid #1976d2;
    padding: 0.12rem 0.16rem;
    margin: 0.14rem 0;
    background: #f5f9ff;
    border-radius: 0.06rem;
}

.message-content :deep(blockquote p) {
    margin: 0;
    color: #666;
    font-size: 0.14rem;
}

.message-content :deep(img) {
    max-width: 100%;
    border-radius: 0.08rem;
    margin: 0.1rem 0;
}

@media screen and (max-width: 750px) {
    .message-content :deep(h2) {
        font-size: 0.34rem !important;
    }
    .message {
        .message-bubble {
            .message-content {
                font-size: 0.28rem;
                :deep(blockquote){
                    p{
                    font-size: 0.28rem !important;

                    }
                }
                h3 {
                    font-size: 0.28rem !important;
                }
                p {
                    font-size: 0.28rem !important;
                }
                li {
                    font-size: 0.28rem !important;
                }
                blockquote p {
                    font-size: 0.28rem !important;
                }
            }
        }
    }
    .message-time {
        font-size: 0.28rem !important;
    }
    .button-group {
        button {
            font-size: 0.28rem !important;
            padding: 0.1rem;
        }
    }
    .chat-header h2 {
        font-size: 0.38rem !important;
    }
    .message-bubble .avatar {
        width: 0.7rem;
        height: 0.7rem;
    }
    .question-category {
        font-size: 0.36rem !important;
        .expand-btn {
            padding: 0.1rem !important;
            font-size: 0.34rem !important;
        }
    }
    .input-group {
        input {
            font-size: 0.28rem !important;
        }
    }
    .send-button {
        font-size: 0.28rem !important;
    }
    .control-btn.clear {
        .clear-icon {
            font-size: 0.28rem !important;
        }
    }
    .header-controls {
        button {
            width: 0.8rem;
            height: 0.8rem;
            border-radius:20px !important;
            font-size: 0.4rem !important;
        }
    }
    .chat-container {
        width: 7.1rem;
        right: 0.22rem;
        bottom: 0.1rem ;
        height: calc(100vh - 50px); /* 移动端全屏 */
    }
    .chat-messages {
        height: calc(100% - 3.5rem); /* 移动端可能需要调整 */
    }
}
</style>