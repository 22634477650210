<template>
    <div id="app">
        <headers :tabactivetan="tabactivetan" v-if="showpase == 1" />
        <router-view v-if="showpase == 1"></router-view>
        <footers v-if="showpase == 1" />

        <div class="cssloader" v-if="showpase == 0">
            <div class="contentcssloader">
                <div class="cssloadersh1"></div>
                <div class="cssloadersh2"></div>
                <h4 class="cssloaderlt">loading</h4>
            </div>
        </div>

    </div>
</template>
<script>
import headers from '@/components/headers';
import footers from "@/components/footer";
export default {
    name: 'App',
    components: {
        headers,
        footers,
    },
    data() {
        return {
            showpase: 0,
            tabactivetan: null,
        };
    },
    watch: {
    },
    created() {
        this.handleResize()
        this.$router.afterEach((to, from) => {
            if (to) {
                this.showpase = 1; // 可根据实际需求调整逻辑
            }
            const list = this.$router.options.routes.map(rel => rel.path);
            const his = [list[1], list[2], list[7], list[8]];
            const currentRoute = to.path;

            // 检查历史路径
            his.forEach((path, index) => {
                if (path === currentRoute) {
                    this.tabactivetan = index;
                }
            });
            // 检查二级子路径
            const validPaths = ['/dasolution', '/damainsolution', '/swimsolution', '/imsolution', '/gmsolution'];
            if (validPaths.includes(currentRoute)) {
                this.tabactivetan = 1;
            }
        });
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            const baseSize = 100; // 设计稿宽度的1/100
            const baseWidth = 1920; // PC端设计稿的宽度
            const mobileBaseWidth = 750; // 移动端设计稿的宽度
            const clientWidth = document.documentElement.clientWidth || window.innerWidth;
            // 判断当前是移动端还是PC端
            let rem;
            if (clientWidth <= mobileBaseWidth) {
                // 移动端，根据设计稿宽度750px计算
                rem = (clientWidth / mobileBaseWidth) * baseSize;
            } else {
                // PC端，使用设计稿宽度1920px计算
                rem = (clientWidth / baseWidth) * baseSize;
            }
            document.documentElement.style.fontSize = `${rem}px`;

            console.log(document.documentElement.style.fontSize,'document.documentElement.style.fontSize');
            
            /*
            this.$nextTick(() => {
              // 滚动到之前的位置
              window.scrollTo(0, 1);
            });
            */
        }
        ,
    }
}
</script>
<style lang="scss">
@import "../public/font-css/main.css";

@function rem($px) {
    $base-font-size: 100; // 根据设计稿的基准字体大小设置
    @return $px / $base-font-size * 1rem;
}

.card_img {
    img {
        width: 0.9rem;
        height: 0.9rem;
    }
}

@import url("https://fonts.googleapis.com/css?family=Roboto:100");
#app {
    position: relative;
}

.cssloader {
    background: #333; /* 背景色 */
    width: 100%; /* 宽度 100% 覆盖整个屏幕 */
    height: 100vh; /* 高度 100vh 覆盖整个视口高度 */
    position: fixed; /* 使用 fixed 定位，使其覆盖整个屏幕 */
    top: 0; /* 顶部对齐视口 */
    left: 0; /* 左侧对齐视口 */
    z-index: 9999; /* 确保该元素位于最上层 */
    display: flex;
    align-items: center;
    justify-content: center;
}

/** loader **/
.cssloadersh1 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.5rem 0.5rem 0 0;
    border-color: #354952 transparent transparent transparent;
    margin: 0 auto;
    animation: shk1 1s ease-in-out infinite normal;
}

.cssloadersh2 {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 0.5rem 0.5rem;
    border-color: transparent transparent #df1a54 transparent;
    margin: -0.5rem auto 0;
    animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
    0% {
        transform: rotate(-360deg);
    }

    100% {
    }
}

@keyframes shk2 {
    0% {
        transform: rotate(360deg);
    }
    100% {
    }
}

.cssloaderlt {
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin: 0.3rem auto;
    text-align: center;
    font-weight: 100;
    letter-spacing: 0.1rem;
}
@media screen and (max-width: 750px) {
    #app {
        width: 7.5rem; 
    }
    body{
        background: #f9fdff;
    }
}
</style>
