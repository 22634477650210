<!--  -->
<template>
    <div class="footr">
        <div class="content">
            <div class="lst">
                <img style="width: 1.87rem;height: .61rem;margin: 0 0 .18rem 0;" src="@/assets/images/logo2.png" alt="">
                <p class="dan">
                    天清数安（天津）科技有限公司
                </p>
                <p class="dan">
                    地址：天津市南开区天开广场5号楼1104
                </p>
                <p class="dan">
                    邮箱：contact@tqds.com.cn
                </p>
                <p class="dan">
                    联系电话：022-27366066
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    解决方案
                </p>
                <p class="shuang shuangactive" @click="solution1">
                    数据资产管理解决方案
                </p>
                <p class="shuang shuangactive" @click="solution2">
                    数据资产入表解决方案
                </p>
                <p class="shuang shuangactive" @click="solution3">
                    水上安全智能解决方案
                </p>
                <p class="shuang shuangactive" @click="solution4">
                    行业大模型解决方案
                </p>
                <p class="shuang shuangactive" @click="solution5">
                    智能地理绘图解决方案
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    产品系列
                </p>
                <p class="shuang shuangactive" @click="product1">
                    智能全周期数据资产安全管理平台
                </p>
                <p class="shuang shuangactive" @click="product2">
                    数据分级分类系统
                </p>
                <p class="shuang shuangactive" @click="product3">
                    智能溺水监测预警系统
                </p>
                <p class="shuang shuangactive" @click="product4">
                    金融行业大模型应用平台
                </p>
                <p class="shuang shuangactive" @click="product5">
                    医疗大模型应用平台
                </p>
                <p class="shuang shuangactive" @click="product6">
                    智能地理绘图系统
                </p>
            </div>
            <div class="lst">
                <p class="dan">
                    关于我们
                </p>
                <p class="shuang shuangactive" @click="about">
                    走进天清数安
                </p>
                <p class="shuang shuangactive" @click="about2">
                    资质与荣誉
                </p>
                <p class="shuang shuangactive" @click="about3">
                    知识产权
                </p>
                <p class="shuang shuangactive" @click="about4">
                    企业风采
                </p>
                <p class="shuang shuangactive" @click="about5">
                    联系我们
                </p>

            </div>
            <div class="lst lst3">
                <div class="dan" style="margin-left: .4rem;">
                    联系我们
                </div>
                <div class="lst2_box">

                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart.jpg">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加微信公众号
                        </p>
                    </div>
                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart2.png">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加客服企业微信
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="title_mobile">
            <div class="title_company">
                天清数安（天津）科技有限公司
            </div>
            <div class="company">
                <div class="company_content">
                    <div class="line-height">地址：天津市南开区天开广场5号楼1104</div>
                    <div class="line-height">邮箱：contact@tqds.com.cn</div>
                    <div class="line-height">联系电话：022-2736606</div>

                </div>
                <div class="company_img">
                    <div class="lst2" style="margin: 0 .3rem 0 0;">
                        <div class="di">
                            <img src="@/assets/images/wxchart.jpg">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加微信公众号
                        </p>
                    </div>
                    <div class="lst2">
                        <div class="di">
                            <img src="@/assets/images/wxchart2.png">
                        </div>
                        <p class="dan" style="font-size:.12rem;opacity: 0.9;text-align: center;margin: .05rem 0 0 0;">
                            添加客服企业微信
                        </p>
                    </div>
                </div>

            </div>


        </div>
        <div class="codes">
            <div class="text">
                <span @click="href(0)" style="margin: 0 .2rem;">
                    津ICP备2024022784号-2
                </span>
                <span @click="href(1)">
                    津公网安备12010402002151号
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            scrollTop: 0,
            img: false
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        showWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            WeChatImg.classList.add('activemouseenter');
            WeChatImg.classList.remove('activemouseleave');
            this.img = true

        },
        hideWeChatImg() {
            let WeChatImg = this.$refs.WeChatImg
            // WeChatImg.classList.add('activemouseleave');
            // WeChatImg.classList.remove('activemouseenter');
            this.img = false
        },
        href(e) {
            if (e == 0) {
                window.open('https://beian.miit.gov.cn')
            } else if (e == 1) {
                window.open('https://beian.mps.gov.cn')
            }
        },
        // 解决方案
        solution1() {
            this.$router.push('/damainsolution')
            this.$bus.$emit("nextaboutitem", 1);
        },
        solution2() {
            this.$router.push('/dasolution')
            this.$bus.$emit("nextaboutitem", 1);
        },
        solution3() {
            this.$router.push('/swimsolution')
            this.$bus.$emit("nextaboutitem", 1);
        },
        solution4() {
            this.$router.push('/imsolution')
            this.$bus.$emit("nextaboutitem", 1);
        },
        solution5() {
            this.$router.push('/gmsolution')
            this.$bus.$emit("nextaboutitem", 1);
        },
        product1() {
            this.$router.push('/sjzc')
            this.$bus.$emit("nextaboutitem", 2);
        },
        product2() {
            this.$router.push('/classification')
            this.$bus.$emit("nextaboutitem", 2);
        },
        product3() {
            this.$router.push('/swimstystem')
            this.$bus.$emit("nextaboutitem", 2);
        },
        product4() {
            this.$router.push('/finance')
            this.$bus.$emit("nextaboutitem", 2);
        },
        product5() {
            this.$router.push('/medical')
            this.$bus.$emit("nextaboutitem", 2);
        },
        product6() {
            this.$router.push('/gis')
            this.$bus.$emit("nextaboutitem", 2);
        },
        about() {
            this.scrollToTarget('#target', 100);
        },
        about2() {
            this.scrollToTarget('#target2', 100);
        },
        about3() {
            this.scrollToTarget('#target3', 100);
        },
        about4() {
            this.scrollToTarget('#target4', 100);
        },
        about5() {
            this.scrollToTarget('#target5', 100);
        },
        scrollToTarget(hash, offset = 100) {
            this.$router.push({ path: '/aboutus', hash }).then(() => {
                // 使用 $nextTick 保证 DOM 更新完毕
                this.$nextTick(() => {
                    // 延时 300ms 确保页面中所有元素已加载（根据实际情况调整延时）
                    setTimeout(() => {
                        const target = document.querySelector(hash);
                        if (target) {
                            // 获取目标元素相对于视口的距离
                            const rect = target.getBoundingClientRect();
                            // 当前页面滚动距离（兼容不同浏览器）
                            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                            // 计算最终滚动位置：目标元素顶部位置 + 额外偏移量
                            const targetPosition = rect.top + scrollTop - offset;
                            window.scrollTo({
                                top: targetPosition,
                                behavior: 'smooth'
                            });
                        }
                    }, 10);
                });
            });
            // 触发其他事件（例如：更新 about 页面的选中状态）
            this.$bus.$emit("nextaboutitem", 3);
        },
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() {

    }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.footr {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 0rem 0.2rem;
    background-color: rgba(51, 54, 56, 1) !important;
}

.footr .content {
    width: 12rem;
    height: 100%;
    margin: 0rem auto;
    display: flex;
    flex-wrap: wrap;
}

.footr .content .lst {
    margin-right: 0.55rem;
}

.footr .content .lst:nth-child(1) {
    margin-top: -0.3rem;
}

.footr .content .lst .dan {
    font-size: 0.15rem;
    color: rgba(255, 255, 255, 1);
    margin: 0 0 0.14rem 0;
}

.footr .content .lst .shuang {
    font-size: 0.14rem;
    color: #ffffff;
    line-height: 0.35rem;
}

.shuangactive {
    &:hover {
        cursor: pointer;
        color: #8f8dec !important;
        text-decoration: dotted;
        text-decoration: underline;
    }
}

.footr .content .lst:nth-child(2),
.footr .content .lst:nth-child(3),
.footr .content .lst:nth-child(4) {
    .shuang {
        color: rgba(153, 153, 153, 1);
    }
}

.footr .content .lst .lst2 {
    margin-left: 0.2rem;
}

.lst2 .di {
    width: 1.01rem;
    height: 1.01rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.lst2_box {
    display: flex;
    margin: 0 0 0 0.2rem;
}

.footr .content .lst3 {
    width: 2.68rem;
    margin-right: 0rem;
    border-left: 0.01rem solid #bbb;
}

.footr .content .lst3 .di {
    margin-top: 0.23rem;
}

.codes {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 0 0;

    .text {
        width: 12rem;
        height: 0.4rem;
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
    }
}

.title_mobile {
    display: none;
}

@media screen and (max-width: 750px) {
    .content {
        display: none !important;
    }

    .codes {
        margin: .1rem 0 0 0 !important;
        padding: 0 0 0 .23rem;

        .text {
            text-align: start !important;
        }

    }

    .title_mobile {
        display: block !important;
        padding: 0 .43rem;

        .title_company {
            font-size: .24rem;
            font-weight: 400;
            letter-spacing: 0rem;
            line-height: .3475rem;
            color: rgba(255, 255, 255, 1);
        }

        .company {
            display: flex;
            justify-content: space-between;

            .company_content {
                color: rgba(255, 255, 255, 1);
                font-size: .2rem;
                font-weight: 400;

                .line-height {
                    line-height: .3475rem;
                }
            }

            .company_img {
                display: flex;
            }
        }

    }
}
</style>
